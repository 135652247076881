<template>
  <ul
    class="flex flex-wrap text-sm md:text-base"
    v-if="breadcrumbList.length"
  >
    <li>
      <router-link
        class="breadcrumb-link"
        :to="{ name: 'home' }"
      >
        <i class="fas fa-home"></i>
      </router-link>
    </li>
    <li
      class="whitespace-nowrap"
      v-for="(breadcrumb, index) in breadcrumbList"
      :key="index"
    >
      <router-link
        class="breadcrumb-link"
        v-if="!!breadcrumb.link"
        :to="{ name: breadcrumb.link }"
      >
        <i v-if="!!breadcrumb.icon" class="mr-1" :class="breadcrumb.icon"></i>
        {{ $t(breadcrumb.name) }}
      </router-link>
      <div v-else>
        <i v-if="!!breadcrumb.icon" class="mr-1" :class="breadcrumb.icon"></i>
        {{ $t(breadcrumb.name) }}
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

interface IBreadcrumb {
  name: string;
  link?: string;
}

export default defineComponent({
  setup () {
    const router = useRouter()
    const breadcrumbList = ref<IBreadcrumb[]|null>(null)
    
    watchEffect(() => {
      const routerMeta = router.currentRoute.value.meta
      if ('breadcrumb' in routerMeta) {
        const breadcrumb = routerMeta.breadcrumb as IBreadcrumb[]
        breadcrumbList.value = breadcrumb
      }
    })

    return {
      breadcrumbList
    }
  }
})
</script>

<style scoped>
@layer components {
  ul {
    @apply flex items-center list-none font-semibold;
  }

  ul > li {
    @apply flex items-center;
  }
  
  ul > li > div,
  ul > li > div i {
    @apply text-gray-500;
  }

  ul > li:not(:last-child)::after {
    @apply mx-2 cursor-auto text-gray-500;
    content: '/';
  }
  
  .breadcrumb-link,
  .breadcrumb-link i {
    @apply text-blue-500 cursor-pointer hover:bg-transparent hover:underline;
  }
}
</style>
